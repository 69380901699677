<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const onCLick = (name) => {
  router.push({ name: name })
}
</script>
<template>
  <div class="container mx-auto mt-8">
    <div class="flex flex-wrap -mx-4">
      <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8 cursor-pointer" @click="onCLick('pharmasan.informes.inventario.stock-mx')">
        <div class="bg-white p-6 rounded-lg shadow-md">
          <h2 class="text-2xl font-semibold mb-4"> stock articulos</h2>
          <p>Reportes xlsx</p>
        </div>
      </div>
      <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8 cursor-pointer" @click="onCLick('pharmasan.informes.inventario.stock-lotes')">
        <div class="bg-white p-6 rounded-lg shadow-md">
          <h2 class="text-2xl font-semibold mb-4"> stock lotes</h2>
          <p>Reportes xlsx</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
